<template>
  <el-row>
    <el-col class="subject">
      <el-col class="container">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs">
          <el-breadcrumb-item :to="{ path: '/softwareList' }">软件商城</el-breadcrumb-item>
          <el-breadcrumb-item>{{ crumb }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-col>

  </el-row>
</template>

<script>
export default {
  name: "Crumbs",
  props: {
    crumb: {
      type: String,
      default() {
        return '';
      }
    },
  }
}
</script>

<style lang="less" scoped>

  .subject {
    background: #EEEEEE;
    .container {
      float: unset;
      width: 1200px;
      margin: auto;
      height: 40px;
      padding-top: 12px;
    }
  }


 /deep/ .el-breadcrumb {
}

</style>

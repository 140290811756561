import {post} from "@/utils/axios"
// 获取支付商品信息
const initData = p => post('/api-seller/apiConsoleGoods/initData', p);

// 获取支付宝支付二维码
const alipay = p => post('/api-seller/alipay/aliPay',p)

// const localSocketUrl = 'http://127.0.0.1:8762/api-common/websocket/';

const testSocketUrl = 'https://mall.api.edensoft.com.cn/api-common/websocket/';

//本地环境Socket地址
// const socketUrl = localSocketUrl;
//
//测试环境Socket地址
const socketUrl = testSocketUrl;

export default {
    initData,
    alipay,
    socketUrl
}

<template>
  <el-row>
    <el-col class="page" >
      <my-crumbs :crumb="crumb"></my-crumbs>
      <el-col class="order-info">
        <el-col class="red-msg">订单提交成功,请尽快支付!</el-col>
        <el-col class="red-order-no">订单号: {{ orderInfo.orderNo }}</el-col>
        <el-col class="red-order-time">时间: {{ orderInfo.duration }}</el-col>
        <el-col class="red-order-amount">应支付金额: {{ orderInfo.price }} RMB</el-col>
      </el-col>
      <el-col class="pay-type">
<!--        <el-col class="wx-pay" :class="{'wx-pay-active':choose=='wx'}" @click.native="choosePay('wx')">
          <img src="../../img/pay/ic_wechat.png" alt="" class="pay-logo">
          <el-col class="title">微信支付</el-col>
          <el-col class="title-us">WeChat payment</el-col>
        </el-col>-->
        <el-col class="ali-pay" :class="{'ali-pay-active':choose=='ali'}" @click.native="choosePay('ali')">
          <img src="../../img/pay/ic_alipay.png" alt="" class="pay-logo">
          <el-col class="title">支付宝支付</el-col>
          <el-col class="title-us">Alipay payment</el-col>
        </el-col>
      </el-col>
      <el-col class="qr-alipay">
        <el-col class="msg">请扫描下方二维码支付！</el-col>
        <el-col>
          <el-col class="qr-img" id="qr-img"> </el-col>
          <img src="../../img/pay/bg_alipay.png" alt="" class="img">
        </el-col>
      </el-col>
    </el-col>
    <!-- 遮罩层 -->
    <el-col class="zz-dialog" v-if="dialogVisible"></el-col>
    <!-- 弹框 -->
    <el-col class="my-dialog" v-if="dialogVisible" >
        <el-col class="img"><img src="../../img/pay/ic_success.png" alt=""></el-col>
        <el-col class="pay-result">支付成功&nbsp;！</el-col>
        <el-col>
          <el-button class="to-shop" @click="to('/cloudMarket')">继续购买</el-button>
          <el-button class="to-home" @click="to('/')">返回首页</el-button>
        </el-col>
      <el-col class="nex-container">
      <span class="time">{{emailCountNum}}秒</span>
      <span class="todo">后自动跳转返回首页</span>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import Crumbs from '../common/Crumbs'
import payApi from '../../api/pay/payApi'
import QRCode from 'qrcodejs2'
export default {
  name: "pay",
  components: {
    'my-crumbs': Crumbs
  },
  data() {
    return {
      objectId: '',
      wsUrl: payApi.socketUrl,
      qrUrl: '',
      emailSingle: false,
      emailCountNum: 5,
      crumb: '确认订单信息',
      orderId: '',
      orderInfo: {
      },
      choose: 'ali',
      dialogVisible: false,
    }
  },
  methods: {
    // 验证码倒计时
    countDown() {
        // 更改btn状态
        let obj = this;
        // obj.emailCountFlag = !obj.emailCountFlag;
        // 设置倒计时
        obj.intervalBtn = setInterval(() => {
            if (obj.emailCountNum <= 0) {
              this.$router.push({path:"/"})
                // 重置btn提示信息
                obj.btnMsg = "获取验证码";
                // 清除定时器
                clearInterval(obj.intervalBtn)
                // 更改btn状态
                // obj.emailCountFlag = !obj.emailCountFlag;
                obj.emailSingle = false;
                // 重置倒计时状态
                obj.emailCountNum = 5;
            } else {
                // 倒计时
                obj.emailSingle = true;
                obj.emailCountNum--;
            }
        }, 1000);
    },
    to(router) {
      this.move()
      if (router == '/cloudMarket'){
        this.$router.push({path: router, query: {productId: this.objectId, orderType:1}});
        return;
      }
      this.$router.push({path: router})


    },
    choosePay(param) {
      this.choose = param
    },
    openDialog(){
      this.dialogVisible = true;
      // 关闭滚动条
      this.stop();
    },
    //禁止滚动条滚动
    stop(){
      document.body.style.overflow = "hidden";
    },
    //恢复滚动条滚动
    move(){
      document.body.style.overflow = 'visible';
    },

    // 初始化支付商品信息
    initData(){
      payApi.initData({ orderId : this.orderId }).then((res) => {
          this.orderInfo = res.data
          var data = {
            createTime: res.data.createTime,
            domianName: res.data.domianName,
            duration: res.data.duration,
            number: res.data.number,
            objectId: res.data.objectId,
            orderNo: res.data.orderNo,
            payType: 1,
            price: res.data.price,
            specsName: res.data.specsName,
            startingTime: res.data.startingTime,
            subject: res.data.subject,
            totalAmount: res.data.totalAmount

          }
          this.objectId = res.data.objectId;
        this.initWebSocket()
       this.getAlipayQr(data)

      })
    },

    // 获取支付宝二维码
    getAlipayQr(p){
      payApi.alipay(p).then((res) => {
         var qrcode = new QRCode(document.getElementById("qr-img"), {
            width: 200,
            height: 200
    });
    qrcode.makeCode(res.data)
      })
    },
    // 使用websocket建立前后端通讯
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        return null;
      }
      var token =  localStorage.getItem('accessToken')
      console.log('token',token)
      // TODO token
      let wsUrl = this.wsUrl +  this.orderInfo.orderNo + "/" + token;
      // let wsUrl = this.wsUrl +  'O202209141713036909' + "/" + token;
      wsUrl = wsUrl.replace("https", "wss").replace("http", "ws");
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.websocketOnMessage
      this.websocket.onopen = this.websocketOnOpen
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketOnClose
    },
    websocketOnMessage(res) {

      console.log('res',res)
      // 数据接收
      // const resData = JSON.parse(res.data);
      if (res.data === "200") {
        console.log('----------------------------------------------')
        this.$message.success("支付成功");
        this.dialogVisible = true;
        this.countDown();
      }
    },
    websocketOnOpen() {
      // 连接建立之后执行send发送数据
      // let data = {'orderNo': this.orderNo};
      // console.log(data);
      // this.websocketSend(JSON.stringify(data));
    },
    websocketOnError() {
      // 连接建立失败后重试
      this.initWebSocket();
    },
    websocketOnClose(e) {
      // 断开连接
      console.log("断开连接", e);
    },
    websocketSend(data) {
      this.websocket.send(data);
    },
    close() {
      this.formValidate = {};
      this.purchaseDialog = false;
    }
  },
  mounted(){
    if (location.href.indexOf("orderId") != -1) {
      this.orderId = this.$route.query.orderId;
    }
    this.initData()

  },

}
</script>

<style lang="less" scoped>
.page {
  height: 700px;
  background: #F7F7F7 100%;

  .order-info {
    width: 1200px;
    height: 60px;
    background: #FFFFFF;
    margin: 24px auto 0px auto;
    float: unset;

    .red-msg {
      width: 183px;
      height: 19px;
      font-size: 14px;
      font-weight: bold;
      color: #D60010;
      line-height: 19px;
      margin: 21px 0px 20px 42px;
    }

    .red-order-no {
      width: 210px;
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      margin: 21px 0px 20px 132px;
    }

    .red-order-time {
      width: 200px;
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      margin: 21px 0px 20px 132px;
    }

    .red-order-amount {
      width: 164px;
      height: 19px;
      font-size: 14px;
      font-weight: bold;
      color: #D60010;
      line-height: 19px;
      margin: 21px 0px 20px 132px;
    }
  }

  .pay-type {
    width: 1200px;
    height: 132px;
    background: #FFFFFF;
    margin: 8px auto 0px auto;
    float: unset;

    .wx-pay-active {
      width: 253px;
      height: 79px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px #c9c9c9;;
      margin: 27px 0px 26px 42px;
      border: 2px solid #1096DB;
    }

    .wx-pay {
      width: 253px;
      height: 79px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px #c9c9c9;;
      margin: 27px 0px 26px 42px;

      .pay-logo {
        width: 51px;
        height: 42px;
        margin: 19px 0px 18px 29px;
        float: left;
      }

      .title {
        width: 56px;
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #414141;
        line-height: 19px;
        float: left;
        margin: 17px 0px 0px 26px;
      }

      .title-us {
        width: 119px;
        height: 19px;
        font-size: 14px;
        color: #414141;
        line-height: 19px;
        margin: 8px 0px 0px 26px;
      }
    }

    .ali-pay-active {
      width: 253px;
      height: 79px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px #c9c9c9;;
      margin: 27px 0px 26px 12px;
      border: 2px solid #1096DB;
    }

    .ali-pay {
      width: 253px;
      height: 79px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px #c9c9c9;;
      margin: 27px 0px 26px 12px;

      .pay-logo {
        margin: 17px 0px 0px 29px;
        float: left;
      }

      .title {
        width: 74px;
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #414141;
        line-height: 19px;
        float: left;
        margin: 17px 0px 0px 26px;
      }

      .title-us {
        width: 119px;
        height: 19px;
        font-size: 14px;
        color: #414141;
        line-height: 19px;
        margin: 8px 0px 0px 26px;
      }
    }
  }

  .qr-alipay {
    width: 1200px;
    height: 359px;
    background: #FFFFFF;
    margin: 8px auto 0px auto;
    float: unset;

    .msg {
      width: 154px;
      height: 19px;
      font-size: 14px;
      font-weight: bold;
      color: #414141;
      line-height: 19px;
      margin: 29px 0px 0px 121px;
    }

    .qr-img {
      width: 200px;
      height: 200px;
      margin: 33px 0px 0px 97px;
      float: left;
    }

    .img {
      width: 212px;
      height: 269px;
      margin: 6px 0px 0px 95px;
    }

    .dialog-pay {
    }
  }

  /deep/.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    border-radius: 2px;
    box-shadow: 0 1px red;
    box-sizing: border-box;
    height: 421px;
    width: 50%;
  }
}
.my-dialog {
  background: #FFFFFF;
  position: fixed;
  left: 25%;
  top: 27%;
  width: 922px;
  height: 404px;
  border-radius: 12px;
  z-index: 10000;
  .img {
    width: 104px;
    height: 104px;
    margin: 51px auto 0px;
    float: unset;
  }
  .pay-result {
    width: 160px;
    height: 40px;
    font-size: 30px;
    color: #414141;
    line-height: 40px;
    margin-left: 394px;
    margin-top: 29px;
  }
  .to-shop {
    width: 103px;
    height: 40px;
    background: #D60010;
    border-radius: 10px;
    color: #FFFFFF;
    margin-left: 320px;
    margin-top: 41px;
  }
  .to-home {
    width: 103px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #D60010;
    color: #D60010;
    margin-left: 77px;
  }
  .nex-container {
    margin-left: 381px;
    margin-top: 29px;
     .time {
       width: 153px;
       height: 19px;
       font-size: 14px;
       color: #D60010;
       line-height: 19px;
     }
     .todo {
       width: 153px;
       height: 19px;
       font-size: 14px;
       color: #414141;
       line-height: 19px;
     }
  }
}
.zz-dialog {
  //width: 500px;
  //height: 150px;
  background: ~"rgb(0 0 0 /40%)";
  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  margin:auto;
  z-index: 9999;
}

</style>
